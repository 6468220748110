import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  selector: 'transfer',
  imports: [MatSlideToggleModule],
  templateUrl: './transfer.component.html',
})
export class Transfer {
  title = 'Перевод средств другому трейдеру';

  modal = true
}
