<div class="page-title">{{ title }}</div>

<div class="row">
  <div class="col-md-6">
    <div class="d-flex gap-3 mb-4">
      <a [routerLink]="'/withdraw'" class="btn btn-light btn-lg btn-rounted ps-4 d-flex align-items-center gap-2 text-nowrap"><img src="assets/svg/my-requests.svg" width="22" > <span>Мои заявки</span> </a>
      <select class="input-select text-light" >
        <option value="" >Все способы</option>
        <option value="b1">Pending</option>
        <option value="b2">Canceled</option>
      </select> 
    </div>
  </div>
  <div class="col">
    <div class="d-flex justify-content-end gap-2">
      <a href="#" class="pagination-item pagination-active">1</a>
      <a href="#" class="pagination-item">2</a>
      <a href="#" class="pagination-item">3</a>
      <a href="#" class="pagination-item">></a>
    </div>
  </div>
</div>


<div class="table-responsive mt-3">
<table class="table">
  <thead>
    <tr>
      <td>ID</td>
      <td>Способ</td>
      <td>Карта</td>
      <td>Сумма</td>
      <td>Статус</td>
      <td>Создан</td>
      <td></td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>12345</td>
      <td>Банковская карта</td>
      <td>*1234</td>
      <td>5000  <button class="btn btn-sm btn-rounted p-1 pt-0 pb-0 bg-yallow-blur font-yallow">2.5%</button></td>
      <td>Pending</td>
      <td>2024-03-19 <br> <span class="font-mute">16:47</span></td>
      <td class="text-center"><button class="btn bg-yallow-blur"><img src="assets/svg/arrow-down.svg" width="22" ></button></td>
    </tr>
    <tr>
      <td>12345</td>
      <td>Банковская карта</td>
      <td>*1234</td>
      <td>5000  <button class="btn btn-sm btn-rounted p-1 pt-0 pb-0 bg-yallow-blur font-yallow">2.5%</button></td>
      <td>Pending</td>
      <td>2024-03-19 <br> <span class="font-mute">16:47</span></td>
      <td class="text-center"><button class="btn bg-yallow-blur"><img src="assets/svg/arrow-down.svg" width="22" ></button></td>
    </tr>
    <tr>
      <td>12345</td>
      <td>Банковская карта</td>
      <td>*1234</td>
      <td>5000  <button class="btn btn-sm btn-rounted p-1 pt-0 pb-0 bg-yallow-blur font-yallow">2.5%</button></td>
      <td>Pending</td>
      <td>2024-03-19 <br> <span class="font-mute">16:47</span></td>
      <td class="text-center"><button class="btn bg-yallow-blur"><img src="assets/svg/arrow-down.svg" width="22" ></button></td>
    </tr>
  </tbody>
</table>
</div>
