<div class="page-title">{{ title }}</div>

<div class="table-responsive">
<table class="table mt-3">
  <thead>
    <tr>
      <td>ID</td>
      <td>Дата</td>
      <td>Курс</td>
      <td>Процент</td>
      <td>Сумма</td>
      <td>Баланс</td>
      <td>Info</td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>123</td>
      <td>2024-03-19 16:47</td>
      <td>150</td>
      <td>93.56</td>
      <td>2%</td>
      <td>18 758.98</td>
      <td><button class="btn btn-light btn-sm">INFO</button></td>
    </tr>

    <tr>
      <td>123</td>
      <td>2024-03-19 16:47</td>
      <td>150</td>
      <td>93.56</td>
      <td>2%</td>
      <td>18 758.98</td>
      <td><button class="btn btn-light btn-sm">INFO</button></td>
    </tr>

    <tr>
      <td>123</td>
      <td>2024-03-19 16:47</td>
      <td>150</td>
      <td>93.56</td>
      <td>2%</td>
      <td>18 758.98</td>
      <td><button class="btn btn-light btn-sm">INFO</button></td>
    </tr>

    <tr>
      <td>123</td>
      <td>2024-03-19 16:47</td>
      <td>150</td>
      <td>93.56</td>
      <td>2%</td>
      <td>18 758.98</td>
      <td><button class="btn btn-light btn-sm">INFO</button></td>
    </tr>
  </tbody>
</table>
</div>