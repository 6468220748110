<div class="page-title"><span class="font-mute">Главная</span> > {{title}}</div>



  <div class="row position-relative">
    <div class="btn-transfer"><img src="/assets/svg/arrow-right.svg" width="23" ></div>
    <div class="col-xl-6">
      <div class="bg-block b-rounted-20 p-5">
        <div class="fs-4">Ваши данные</div>

        <div class="mt-4">
          <div class="mb-1">Ваш ID</div>
          <div class="block-input-icon w-300">
            <input type="text" class="input-border font-yallow b-rounted-10" value="82374724739294" >
            <img src="/assets/svg/copy.svg" width="24">
          </div>
        </div>

        <div class="mt-4">
          <div class="mb-1">Ваш username</div>
          <div class="block-input-icon w-300">
            <input type="text" class="input-border font-yallow b-rounted-10" value="Designer_3000" >
            <img src="/assets/svg/copy.svg" width="24">
          </div>
        </div>
        
      </div>
    </div>
    <div class="col-xl-6">
      <div class="bg-light b-rounted-20 p-5">
        <div class="fs-4 font-dark">Введите данные трейдера</div>

        <div class="mt-4">
          <div class="mb-1 font-dark">Username или ID трейдера </div>
          <div class="w-300">
            <input type="text" class="input-border b-rounted-10 font-dark bg-gray" value="82374724739294" >
          </div>
        </div>

        <div class="mt-4">
          <div class="mb-1 font-dark">Введите сумму (RUB)</div>
          <div class="w-300">
            <input type="text" class="input-border b-rounted-10 font-dark bg-gray" value="5000" >
          </div>
        </div>
        
      </div>
    </div>
  </div>

  <button class="btn btn-rounted bg-yallow-blur w-100 p-3 mt-4 font-yallow">Отправить</button>

