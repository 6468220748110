import { inject } from '@angular/core';
import * as cookie from "@/common/cookies";
import { Router, CanActivateFn } from '@angular/router';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const auth = cookie.get("auth") ?? null

  if(!auth){
    router.navigate(['login'])
    return false;
  }

  return true;
};

export const authGuardLogin: CanActivateFn = () => {
  const router = inject(Router);
  const auth = cookie.get("auth") ?? null

  if(auth){
    router.navigate([''])
    return true;
  }

  return false;
};
