<div class="d-flex justify-content-between ">
  <img src="/assets/svg/logo.svg">
  <a href="#" class="d-flex gap-2 link-support" ><img src="/assets/svg/support.svg" width="24">Тех.поддержка</a>
</div>


<div class="block-login">

  <div class="row">

    <div class="col-xl-6">
      <div class="d-flex align-items-start">
        <img src="/assets/svg/star-login.svg" width="100">
        <span class="mt-100 fs-50">Добро <br> пожаловать, <br> <span class="font-gray">выполните <br> вход</span></span>
      </div>
    </div>

    <div class="col-xl-6">
      <div class="container bg-block b-rounted p-4">
        <div class="mt-4">
          <div class="mb-1">Введите ваш логин</div>
          <input type="text" class="input-border b-rounted-10 bg-dark-2 border-none font-yallow" [(ngModel)]="username" >
        </div>
        <div class="mt-4">
          <div class="mb-1">Введите пароль</div>
          <input type="password" class="input-border b-rounted-10 bg-dark-2 border-none font-yallow" [(ngModel)]="password" >
        </div>

        <div class="d-flex gap-4 mt-4 align-items-center">
          <button class="btn btn-light btn-rounted p-3 ps-4 pe-4" (click)="login()" *ngIf="!loading">Войти в систему</button>
          <span *ngIf="loading">Loading</span>
           <a href="#" class="font-gray">Восстановить пароль</a>
        </div>
      </div>
    </div>
  </div>

</div>