<div class="page-title">{{ title }}</div>

<div class="table-responsive">
<table class="table table-striped table-hover table-dark mt-3">
  <thead>
    <tr>
      <td>ID</td>
      <td>Дата</td>
      <td>Сумма</td>
      <td>Тип</td>
      <td>Payment ID</td>
      <td>Баланс</td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>123</td>
      <td>2024-03-19 16:47</td>
      <td class="text-danger">-150</td>
      <td>Списание</td>
      <td><a href="#" class="text-light">1234567</a></td>
      <td>18 758.98</td>
    </tr>

    <tr>
      <td>123</td>
      <td>2024-03-19 16:47</td>
      <td class="text-success">+150</td>
      <td>Пополнение</td>
      <td><a href="#" class="text-light">1234567</a></td>
      <td>18 758.98</td>
    </tr>
    <tr>
      <td>123</td>
      <td>2024-03-19 16:47</td>
      <td class="text-danger">-150</td>
      <td>Списание</td>
      <td><a href="#" class="text-light">1234567</a></td>
      <td>18 758.98</td>
    </tr>
  </tbody>
</table>
</div>