import { SharedModule } from '@/app/chared.module';
import { defaultFilter } from '@/common/default';
import { isRole } from '@/common/helpers';
import { api } from '@/network';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'cards',
  templateUrl: './component.html',
  imports: [SharedModule],
})
export class Cards {
  
  constructor(public title: Title, readonly router: Router){}
  
  data:any  = null
  loading: boolean = true
  params = defaultFilter
  isRole = isRole

  async ngOnInit() {
    await this.getData()
  }


  archive = async (data: any) => {
    console.log(data)

    const res = await api.toggleIsSbp(this.data.data[data.index].id);

    if(res.data.success){
      this.data.data[data.index].is_sbp = !this.data.data[data.index].is_spb 
    }

    //console.log(res);
    //this.data.data[data.index].is_archived
    //this.data.data[data.index].is_archived = true
    //this.data.data.filter((item: any) => item.id === data.id)[0].is_archived = true
  }

  deArchive = async (data: any) => {
    this.data.data[data.index].is_archived = false
    //this.data.data.filter((item: any) => item.id === data.id)[0].is_archived = false
  }

  clickCard = async (row: any) => {
    this.router.navigate([`/cards/edit/${row.cards_id}`])
  }

  config: any = {
    ignore: [
      'user_id'
    ],
    filters: [
        {
          field: 'id',
          type: 'input',
          label: 'ID'
        },
        {
          field: 'is_archived',
          type: 'list',
          label: 'Архив',
          list: [
            {
              value: "true",
              label: 'Да'
            },
            {
              value: "false",
              label: 'Нет'
            },
          ]
        },
        {
          field: 'is_sbp',
          type: 'boolean',
          label: 'СБП',
        },
      ],
      actions: {
         is_sbp: [
          {
            title: 'true',
            action: this.archive,
          },
          {
            title: 'false',
            action: this.deArchive,
          }
         ]
      },
      clickRow: this.clickCard
  }

  async setFilter(query: any){
    this.params = query
    await this.getData()
  }
  

  async getData(){
    this.loading = true

    const res = await api.getCards(this.params)

    this.loading = false

    console.log(res.data)

    this.data = res.data
    
  }

}
