import { Routes } from '@angular/router';
import { Cards } from '../../pages/cards/cards.component';
import { Payments } from '../../pages/trader/payments/payments.component';
import { HomeComponent } from '../../pages/home/home.component';
import { Operations } from '../../pages/trader/operations/operations.component';
import { EditCard } from '../../pages/trader/edit-card/edit-card.component';
import { Profile } from '../../pages/profile/profile.component';
import { Deposits } from '../../pages/trader/deposits/deposits.component';
import {Login} from "@/pages/login/login.component";
import {authGuard, authGuardLogin} from "@/common/auth.guard";
import * as cookie from "@/common/cookies";
import { AdminRoutes, CustomRoute } from './admin.routes';
import { TraderRoutes } from './trader.routes';
import { Notifs } from '@/pages/notifs/notifs.component';
import { CheckerRoutes } from './checker.routes';

const role = cookie.get("auth") ? JSON.parse(cookie.get("auth") as string).role : null
console.log(role)

const routesMap = {
  ROLE_ADMIN: AdminRoutes,
  ROLE_TRADER: TraderRoutes,
  ROLE_CHECKER: CheckerRoutes
} as Record<string, Routes>

// общие роуты
const routesAll: CustomRoute[] = [
  {
    path: '',
    component: Login,
    canActivate: [authGuard],
    title: 'Login home',
  },
  {
    path: 'notifs',
    component: Notifs,
    canActivate: [authGuard],
    title: 'Уведомления',
  },

  {
    path: 'login',
    component: Login,
    title: 'Login',
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
  
];

export const routes: Routes = [
  ...routesMap[role] ?? [],
  ...routesAll
];


