import { Component } from '@angular/core';
import {Router} from "@angular/router";
import { api } from '@/network';
import { Bank } from '@/common/types';
import { SharedModule } from '@/app/chared.module';
import { Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'add-bank',
  templateUrl: './component.html',
  imports: [SharedModule],
})
export class AddBank {
  constructor(public title: Title, readonly router: Router){}

  loadingSent: boolean = false
  banks: Bank[] = []

  data = {
    name: "",
    key: ""
  }

  async action(){
    if(this.loadingSent) return;

    this.loadingSent = true
    const res = await api.createBank(this.data);
    this.loadingSent = false

    if(res.data.success){
      this.router.navigate(['/banks'])
    }
    
  }


  
}
