import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  selector: 'settings',
  imports: [MatSlideToggleModule],
  templateUrl: './settings.component.html',
})
export class Settings {
  title = 'Настройки';

  modal = true
}
