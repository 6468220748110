import { defaultFilter } from '@/common/default';
import { SmartTable } from '@/components/smart-table/smart-table.component';
import { api } from '@/network';
import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  selector: 'notifs',
  imports: [MatSlideToggleModule, SmartTable],
  templateUrl: './notifs.component.html',
})
export class Notifs {
  title = 'Уведомления';

  modal = true

  data:any  = null
  loading: boolean = true
  params = defaultFilter

  async ngOnInit() {
    await this.getData()
  }

  config: any = {
    ignore: [
      'color',
      'is_viewed',
      'user_id',
    ],
    filters: [],
    actions: {},
  }

  async setFilter(query: any){
    this.params = query
    await this.getData()
  }
  

  async getData(){
    this.loading = true

    const res = await api.getNotifs(this.params)

    this.loading = false

    console.log(res.data)

    this.data = res.data
    
  }
}
