import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'withdraw-pull',
  imports: [MatSlideToggleModule, RouterModule],
  templateUrl: './withdraw-pull.component.html',
})
export class WithdrawPull {
  title = 'Пул заявок на вывод';

  modal = true
}
