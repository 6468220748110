import { SharedModule } from '@/app/chared.module';
import { defaultFilter } from '@/common/default';
import { isRole } from '@/common/helpers';
import { Bank } from '@/common/types';
import { api } from '@/network';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'banks',
  templateUrl: './template.component.html',
  imports: [SharedModule],
})
export class Banks {
  
  constructor(public title: Title, readonly router: Router){}

  data: Bank[]  = []
  loading: boolean = true
  params = defaultFilter
  isRole = isRole


  async ngOnInit() {
    await this.getData()
  }

  async getData(){

    this.loading = true
    this.data = await api.getBanks();
    this.loading = false

    console.log(this.data)

  
  }

  clickCard = async (row: any) => {
    this.router.navigate([`/cards/edit/${row.id}`])
  }

  config: any = {
    ignore: [
      'user_id'
    ],
    filters: {},
    views: {},
    

  }

  async setFilter(query: any){
    this.params = query
    await this.getData()
  }


}
