import { SharedModule } from '@/app/chared.module';
import { getFileExtension } from '@/common/helpers';
import { api } from '@/network';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'checker',
  imports: [SharedModule],
  templateUrl: './component.html',
})
export class CheckerCreateRequest {

  constructor(public title: Title, readonly router: Router){}
  
  amount = ''
  message = ''
  loadingSend: boolean = false
  loadingFind: boolean = false
  files: any[] = [];
  paymentId: string = ''
  payment: any = null

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.convertFileToBase64(files[i]);
      }
    }
  }


  convertFileToBase64(file: File): void {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result as string;
      const fileName = file.name;
      const fileExtension = getFileExtension(fileName);

      this.files.push({
        base64: base64Data,
        name: fileName,
        extension: fileExtension,
      });
    };
    reader.readAsDataURL(file);
  }

  removeFile = (index: number) => {
    this.files.splice(index, 1)
  }

  send = async () => {
    if(this.message == '') return

    const files: string[] = this.files.map((item) => item.base64)

    this.loadingSend = true

    const res = await api.createCheckerRequest({
      amount: this.amount,
      message: this.message,
      images: files,
      payment_id: this.paymentId
    })

    this.loadingSend = false

    if(res.data.success){
      this.router.navigate([`checker-request/${res.data.id}`])
    }
  }

  findPayment = async () => {
    this.payment = null

    this.loadingFind = true
    const res = await api.getPayment(Number(this.paymentId))
    this.loadingFind = false

    if(res.data.success){
      this.payment = res.data.data
    }
  }


}
