import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  selector: 'key-app',
  imports: [MatSlideToggleModule],
  templateUrl: './key-app.component.html',
})
export class KeyApp {
  title = 'Key App';

}
