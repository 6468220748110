import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  selector: 'checker',
  imports: [MatSlideToggleModule],
  templateUrl: './component.html',
})
export class CheckerRequest {
  title = 'Заявка чекера на ввод';
}
