<div class="page-title"><span class="font-mute">Пул заявок на вывод</span> > {{title}}</div>

<div class="d-flex justify-content-between">

<div class="d-flex gap-3 mb-4">
  <button  class="btn btn-light btn-rounted text-nowrap ps-3 pe-3">Все</button>
  <button  class="btn btn-empty font-orange btn-rounted text-nowrap ps-3 pe-3">Pending</button>
  <button  class="btn btn-empty font-yallow btn-rounted text-nowrap ps-3 pe-3">Confirmed</button>
  <button  class="btn btn-empty font-red btn-rounted text-nowrap ps-3 pe-3">Canceled</button>
  
  <select class="input-select text-light" >
    <option value="">Все способы</option>
    <option value="b1">Pending</option>
    <option value="b2">Canceled</option>
  </select>

  <div class="block-input-icon">
    <input type="text" class="input-border w-300" placeholder="Поиск по ID" >
    <img src="/assets/svg/search.svg" width="24">
  </div>
</div>



<div class="d-flex gap-2">
  <a href="#" class="pagination-item pagination-active">1</a>
  <a href="#" class="pagination-item">2</a>
  <a href="#" class="pagination-item">3</a>
  <a href="#" class="pagination-item">></a>
</div>

</div>

<table class="table mt-3">
  <thead>
    <tr>
      <td>ID</td>
      <td>Способ</td>
      <td>Карта</td>
      <td>Сумма</td>
      <td>Статус</td>
      <td>Создан</td>
      <td></td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>12345</td>
      <td>Банковская карта</td>
      <td>*1234</td>
      <td>5000  <button class="btn btn-sm btn-rounted p-1 pt-0 pb-0 bg-yallow-blur font-yallow">2.5%</button></td>
      <td>Pending</td>
      <td>2024-03-19 <br> <span class="font-mute">16:47</span></td>
      <td class="text-center"><button class="btn bg-yallow-blur"><img src="assets/svg/arrow-down.svg" width="22" ></button></td>
    </tr>
    <tr>
      <td>12345</td>
      <td>Банковская карта</td>
      <td>*1234</td>
      <td>5000  <button class="btn btn-sm btn-rounted p-1 pt-0 pb-0 bg-yallow-blur font-yallow">2.5%</button></td>
      <td>Pending</td>
      <td>2024-03-19 <br> <span class="font-mute">16:47</span></td>
      <td class="text-center"><button class="btn bg-yallow-blur"><img src="assets/svg/arrow-down.svg" width="22" ></button></td>
    </tr>
    <tr>
      <td>12345</td>
      <td>Банковская карта</td>
      <td>*1234</td>
      <td>5000  <button class="btn btn-sm btn-rounted p-1 pt-0 pb-0 bg-yallow-blur font-yallow">2.5%</button></td>
      <td>Pending</td>
      <td>2024-03-19 <br> <span class="font-mute">16:47</span></td>
      <td class="text-center"><button class="btn bg-yallow-blur"><img src="assets/svg/arrow-down.svg" width="22" ></button></td>
    </tr>
  </tbody>
</table>

