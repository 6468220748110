export const namesFields: Record<string, string> = {
    id: 'ID',
    number: 'Номер',
    name: 'Имя',
    phone_number: 'Телефон',
    is_active: 'Активно',
    is_archived: 'Архив',
    is_sbp: 'СПБ',
    user_id: 'ID пользователя',
    created_at: 'Создан',
    hash_id: 'Хеш',
    amount: 'Сумма',
    amount_real: 'Измен. сумма',
    merchants_user_id: 'ID user мерчанта',
    merchants_payment_id: 'ID платежа мерчанта',
    fee: 'Комиссия',
    user_paid: 'Оплачено пользователем',
    status: 'Статус',
    card_id: 'ID карты',
    merchant_id: 'ID мерчанта',
    currency_id: 'ID валюты',
    body: 'Сообщение',
    checker_message: 'Сообщение чекера',
    trader_message: 'Сообщение трейдера',
    new_amount: 'Новая сумма',
    files: 'Файлы',
    checker_files: 'Файлы чекера',
    trader_files: 'Файлы трейдера',
    

}