import { Component, NgModule, OnInit } from '@angular/core';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AppNavigation } from '../components/navigation/navigation/navigation.component';
import { Cards } from '../pages/cards/cards.component';
import { CommonModule, NgIf } from '@angular/common';
import { HomeComponent } from '../pages/home/home.component';
import { Payments } from '../pages/trader/payments/payments.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EditCard } from '../pages/trader/edit-card/edit-card.component';
import { Profile } from '../pages/profile/profile.component';
import { Deposits } from '../pages/trader/deposits/deposits.component';
import * as cookie from "@/common/cookies";
import {Login} from "@/pages/login/login.component";
import { SmartTable } from '@/components/smart-table/smart-table.component';
import { AddCard } from '@/pages/trader/add-card/add-card.component';
import { FormsModule } from '@angular/forms';
import { TraderCheckerRequests } from '@/pages/trader/checker-requests/checker-requests.component';
import { TraderCheckerRequest } from '@/pages/trader/checker-request/checker-request.component';
import { WithdrawPull } from '@/pages/trader/withdraw-pull/withdraw-pull.component';
import { Withdraw } from '@/pages/trader/withdraw/withdraw.component';
import { Notifs } from '@/pages/notifs/notifs.component';
import { Settings } from '@/pages/trader/settings/settings.component';
import { Transfer } from '@/pages/trader/transfer/transfer.component';
import { Wallet } from '@/pages/trader/wallet/wallet.component';
import { KeyApp } from '@/pages/trader/key-app/key-app.component';
import { CheckerRequestsPayments } from '@/pages/checker/requests-payments/component';
import { CheckerRequest } from '@/pages/checker/request/component';
import { CheckerRequestsWithdraw } from '@/pages/checker/requests-withdraw/component';
import { CheckerCreateRequest } from '@/pages/checker/create-request/component';
import { EditBalanceTrader } from '@/pages/admin/edit-balance-trader/component';
import { DepositMerchant } from '@/pages/admin/deposit-merchant/component';
import { SharedModule } from './chared.module';
import { Banks } from '@/pages/admin/banks/banks.component';
import { AddBank } from '@/pages/admin/add-bank/add-bank.component';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    AppNavigation,
    Cards,
    HomeComponent,
    Payments,
    MatSlideToggleModule,
    EditCard,
    AddCard,
    Profile,
    Deposits,
    Login,
    SmartTable,
    TraderCheckerRequests,
    TraderCheckerRequest,
    WithdrawPull,
    Withdraw,
    Notifs,
    Settings,
    Transfer,
    Wallet,
    KeyApp,
    CheckerRequestsPayments,
    CheckerRequest,
    CheckerRequestsWithdraw,
    CheckerCreateRequest,
    EditBalanceTrader,
    DepositMerchant,
    SharedModule,
    Banks,
    AddBank
    
  ],
  providers:[NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'BLOOMIS';

  constructor(router: Router){
    // if(!this.isHeader){
    //   router.navigate(['/login'])
    // }

   
  }

  isHeader = !!(cookie.get("auth") ?? false)

  
   
}
