<div class="page-title mt-4 mb-3"><span class="font-mute">{{ title }}</span> > Заявка #58348</div>

<div class="block-input-icon col-6 m-lg-0 mt-2">
  <input type="text" class="input-border w-100 d-block" placeholder="Поиск по ID" >
  <img src="/assets/svg/search.svg" width="24">
</div>

<div class="card-rounted mt-4 p-5">

  <div class="d-flex gap-3 flex-column flex-lg-row">
    <div>
      <div class="mb-1">Выберите тип операции</div>
      <button class="btn btn-opacity btn-opacity-yallow me-2">Депозит</button> 
      <button class="btn btn-opacity btn-opacity-red me-2">Списание </button>
    </div>

    <div>
      <div class="mb-1">Трейдер</div>
      <select class="input-select-2 text-light" >
        <option value="">tradertest</option>
        <option value="b1">trader 2</option>
        <option value="b2">tarder 3</option>
      </select>   
    </div>

    <div>
      <div class="mb-1">Сумма USDT</div>
      <input type="text" class="input-small" value="100" />   
    </div>

    <div>
      <div class="mb-1">Текущий курс</div>
      <div class="d-flex gap-2">
        <input type="text" class="input-small" value="98.43" />
        <button class="btn bg-dark-2"><img src="/assets/svg/update-yallow.svg" alt=""></button>
      </div>
    </div>

    <div>
      <div class="mb-1">Процент трейдера</div>
      <input type="text" class="input-small" value="2" />   
    </div>

  </div>

  <button class="btn bg-yallow-blur font-yallow btn-rounted w-100 mt-4 p-3">Отправить <span class="text-light">2438 USDT</span></button>
</div>

