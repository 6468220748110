
  <div class="page-title m-0">{{ title }}</div>
  

    <div class="row">

      <div class="col-lg-8 d-flex gap-3">
        <div class="d-flex">
          <select class="input-select text-light" >
            <option value="">Все способы</option>
            <option value="b1">Pending</option>
            <option value="b2">Canceled</option>
          </select>
        </div>

        <div class="d-flex">
          <select class="input-select text-light" >
            <option value="">Все способы</option>
            <option value="b1">Pending</option>
            <option value="b2">Canceled</option>
          </select>
        </div>

        <div class="d-flex">
          <select class="input-select text-light" >
            <option value="">Все способы</option>
            <option value="b1">Pending</option>
            <option value="b2">Canceled</option>
          </select>
        </div>
      </div>
    
      <div class="block-input-icon col m-lg-0 mt-2">
        <input type="text" class="input-border w-100 d-block" placeholder="Поиск по ID" >
        <img src="/assets/svg/search.svg" width="24">
      </div>

    </div>
  



<div class="table-responsive mt-3">
<table class="table">
  <thead>
    <tr>
      <td>ID</td>
      <td>User ID</td>
      <td>Сумма</td>
      <td>Измен. сумма</td>
      <td>Номер карты</td>
      <td>ФИО</td>
      <td>Статус</td>
      <td>Дата</td>
      <td>Валюта</td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>123</td>
      <td>321</td>
      <td>100</td>
      <td>150</td>
      <td>1234123412341234</td>
      <td>Иван Иванович Иванов</td>
      <td><span class="font-yallow">True</span></td>
      <td>2024-03-19  <span class="font-mute">16:47</span></td>
      <td>RUB</td>
    </tr>
    <tr>
      <td>123</td>
      <td>321</td>
      <td>100</td>
      <td>150</td>
      <td>1234123412341234</td>
      <td>Иван Иванович Иванов</td>
      <td class="d-flex gap-2"><span class="font-orange">False</span>  <img src="/assets/svg/check-yallow.svg" width="24" (click)="modal = true"></td>
      <td>2024-03-19  <span class="font-mute">16:47</span></td>
      <td>RUB</td>
    </tr>
    <tr>
      <td>123</td>
      <td>321</td>
      <td>100</td>
      <td>150</td>
      <td>1234123412341234</td>
      <td>Иван Иванович Иванов</td>
      <td class="d-flex gap-2"><span class="font-red">Canceled</span> <img src="/assets/svg/check-yallow.svg" width="24"></td>
      <td>2024-03-19  <span class="font-mute">16:47</span></td>
      <td>RUB</td>
    </tr>
    <tr>
      <td>123</td>
      <td>321</td>
      <td>100</td>
      <td>150</td>
      <td>1234123412341234</td>
      <td>Иван Иванович Иванов</td>
      <td class="d-flex gap-2"><span class="font-red">Canceled</span> <img src="/assets/svg/check-yallow.svg" width="24"></td>
      <td>2024-03-19  <span class="font-mute">16:47</span></td>
      <td>RUB</td>
    </tr>
    <tr>
      <td>123</td>
      <td>321</td>
      <td>100</td>
      <td>150</td>
      <td>1234123412341234</td>
      <td>Иван Иванович Иванов</td>
      <td class="d-flex gap-2"><span class="font-red">Canceled</span> <img src="/assets/svg/check-yallow.svg" width="24"></td>
      <td>2024-03-19  <span class="font-mute">16:47</span></td>
      <td>RUB</td>
    </tr>
  </tbody>
</table>
</div>

<div class="d-flex  justify-content-end justify-content-md-end  justify-content-sm-between gap-3 mt-3">

  <div class="d-flex gap-2">
    <span>Позиций</span>
    <select class="select-pagination" >
      <option value="">100</option>
      <option value="200">200</option>
      <option value="300">300</option>
    </select>
  </div>

  
  <div class="d-flex justify-content-center gap-2 align-items-center pagination-small">
    <img src="assets/svg/pagination-left-1.svg" width="24">
    <img src="assets/svg/pagination-left-2.svg" width="24">
    <span class="font-yallow ps-2 pe-2">1</span>
    <img src="assets/svg/pagination-right-1.svg" width="24">
    <img src="assets/svg/pagination-right-2.svg" width="24">
  </div>
  

</div>

<div class="modal-block" *ngIf="modal">
  <div class="model-content">
    <div class="title">Вы уверены, что подтверждаете заявку?</div>
    <div class="d-flex gap-4 justify-content-center mt-4">
      <button class="btn font-yallow btn-rounted bg-yallow-blur p-2 ps-4 pe-4" (click)="modal = false">Да <img src="/assets/svg/check-light.svg" width="24"></button>
      <button class="btn font-yallow btn-rounted bg-red-blur p-2 ps-4 pe-4" (click)="modal = false">Нет <img src="/assets/svg/close-red.svg" width="24"></button>
    </div>
  </div>
</div>