import {Component, inject} from '@angular/core';

import { api } from "@/network"
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Router} from "@angular/router";
import * as cookie from "@/common/cookies";
import {CommonModule, NgIf} from "@angular/common";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    MatFormFieldModule, MatInputModule, MatSelectModule, FormsModule, ReactiveFormsModule, CommonModule
  ],
  templateUrl: './login.component.html',
})
export class Login {
  title = 'Login';
  loading = false
  username = 'test3'
  password = '1234'

  constructor(readonly router: Router){}

  async login(){
    try {
      this.loading = true
      const res = await api.login({username: this.username, password: this.password})
      console.log(res)
      if(res.data.success) {
        cookie.set("auth", JSON.stringify({token: res.data.token, role: res.data.role }))
        window.location.href = '/'
        //this.router.navigate([''])
      }
    } catch (error) {
      
    } finally{
      this.loading = false
    }
    
    


    
  }


}
