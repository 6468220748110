<div class="page-title">{{ title.getTitle() }}</div>

<div class="spinner-border spinner-border-sm text-success me-3" role="status" *ngIf="loadingBanks">
  <span class="visually-hidden">Loading...</span>
</div>

<div class="container bg-block b-rounted p-4" *ngIf="!loadingBanks">
  <div class="row">
    <div class="col-xl-6">
      <label class="mb-1">Номер карты</label>
      <input type="text" class="input" maxlength="16" placeholder="0000000000000000" [(ngModel)]="data.number" />   
    </div>
    <div class="col-xl-6">
      <label class="mb-1">Номер телефона</label>
      <input type="text" class="input" placeholder="+7(999)999-99-99" [(ngModel)]="data.phone_number" />   
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-xl-6">
      <label class="mb-1">Укажите ФИО</label>
      <input type="text" class="input" placeholder="ФИО" [(ngModel)]="data.name"/>   
    </div>
    <div class="col-xl-6">
      <label class="mb-1">Выберите банк</label>
      <select class="input" [(ngModel)]="data.bank">
        <option [value]="item.key" *ngFor="let item of banks;">{{ item.name }}</option>
      </select>   
    </div>
  </div>

  <div class="d-flex gap-4 mt-4">
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox">
      <label class="form-check-label">Автоматика по номеру счета</label>
    </div>

    <div class="form-check form-switch ">
      <input class="form-check-input" type="checkbox" [(ngModel)]="data.is_sbp">
      <label class="form-check-label" >СБП</label>
    </div>
  </div>

  <button class="btn btn-light btn-rounted w-100 mt-4 p-3" (click)="action()">

    <div class="spinner-border spinner-border-sm text-success me-3" role="status" *ngIf="loadingSent">
      <span class="visually-hidden">Loading...</span>
    </div>
    
    <span *ngIf="!loadingSent">Сохранить информацию</span> 
  </button>
</div>