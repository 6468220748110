import { authGuard } from "@/common/auth.guard";
import { Profile } from "@/pages/profile/profile.component";
import { Cards } from "@/pages/cards/cards.component";
import { Payments } from "@/pages/trader/payments/payments.component";
import { Route, Routes } from "@angular/router";
import { Banks } from "@/pages/admin/banks/banks.component";
import { AddBank } from "@/pages/admin/add-bank/add-bank.component";

export interface CustomRoute extends Route {
    icon?: string;
    showMenu?: boolean;
}

export const AdminRoutes: CustomRoute[] = [
  {
    path: 'cards',
    component: Cards,
    canActivate: [authGuard],
    title: 'Карты',
    icon: 'card.svg',
    showMenu: true
  },
  {
    path: 'banks',
    component: Banks,
    canActivate: [authGuard],
    title: 'Банки',
    icon: 'bank.svg',
    showMenu: true
  },
  {
    path: 'banks/add',
    component: AddBank,
    canActivate: [authGuard],
    title: 'Создать банк'
  },

];