import { SharedModule } from '@/app/chared.module';
import { getFileExtension, getStatusCheckerRequestHtml } from '@/common/helpers';
import { api } from '@/network';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  selector: 'checker-request',
  imports: [SharedModule],
  templateUrl: './component.html',
})
export class TraderCheckerRequest {
  constructor(public title: Title,  public route: ActivatedRoute){}

  requestId: number = Number(this.route.snapshot.paramMap.get('id'))
  loadingRequest: boolean = false
  loadingPayment: boolean = false
  loadingSent: boolean = false
  request: any = {}
  payment: any = {}
  selectStatus: string = ''
  newAmount: string = ''
  traderMessage: string = ''
  files: any[] = []
  getStatusCheckerRequestHtml = getStatusCheckerRequestHtml

  async ngOnInit() {
    await this.getRequest()
  }

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.convertFileToBase64(files[i]);
      }
    }
  }

  convertFileToBase64(file: File): void {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result as string;
      const fileName = file.name;
      const fileExtension = getFileExtension(fileName);

      this.files.push({
        base64: base64Data,
        name: fileName,
        extension: fileExtension,
      });
    };
    reader.readAsDataURL(file);
  }

  getPayment = async (paymentId: number) => {

    this.loadingPayment = true
    const res = await api.getPayment(paymentId)
    this.loadingPayment = false

    if(res.data.success){
      this.payment = res.data.data
    }
  }

  async getRequest(loadingPayment = true){
    this.loadingRequest = true
    const res = await api.getCheckerRequest(this.requestId)
    this.loadingRequest = false

    this.request = {
      ...res.data.data,
      checker_files: JSON.parse(res.data.data.checker_files) ?? [],
      trader_files: JSON.parse(res.data.data.trader_files) ?? []
    }

    if(loadingPayment)
      await this.getPayment(res.data.data.payment_id);

  }

  async confirm(){
    this.loadingSent = true
    const res = await api.checkerRequestTraderConfirm({
      new_amount: this.newAmount,
      checker_request_id: this.requestId
    })
    this.loadingSent = false

    if(res.data.success){
      this.selectStatus = ''
      this.newAmount = ''
      await this.getRequest(false)
    }
  }

  async cancel(){
    const files: string[] = this.files.map((item) => item.base64)

    this.loadingSent = true
    const res = await api.checkerRequestTraderCancel({
      trader_message: this.traderMessage,
      checker_request_id: this.requestId,
      images: files
    })
    this.loadingSent = false

    if(res.data.success){
      this.selectStatus = ''
      this.files = []
      this.traderMessage = ''
      await this.getRequest(false)
    }
  }





}
