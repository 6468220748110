import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { api } from '@/network';
import { Title } from '@angular/platform-browser';
import { SharedModule } from '@/app/chared.module';
import { Bank } from '@/common/types';

@Component({
  standalone: true,
  selector: 'edit-card',
  templateUrl: './component.html',
  imports: [SharedModule],
})
export class EditCard {
  
  constructor(public title: Title, readonly router: Router, public route: ActivatedRoute){}

  cardId = Number(this.route.snapshot.paramMap.get('id'))
  loading = false
  loadingData = false
  loadingBanks = false
  banks: Bank[] = []

  data = {
    number: "", 
    name: "",
    phone_number: "",
    is_sbp: false,
    user_id: 1
  } as any

  async ngOnInit() {
    await this.getCard()
    await this.getBanks()
  }

  async getBanks(){
    this.loadingBanks = true
    const res = await api.getBanks();
    this.banks = res.data;
    this.loadingBanks = false
  }

  async getCard(){
    this.loadingData = true
    const res = await api.getCard(this.cardId)
    this.loadingData = false
    
    console.log(res)

     // обработать объект на бэке, возвращается is_sbp: "true", нужно is_sbp: true
    this.data = {...this.data, ...res.data.data}
  }

  async action(){
    if(this.loading) return;
    //!! обработать на бэке
    delete this.data.created_at
    console.log(this.data)
    this.loading = true
    const res = await api.updateCard(this.cardId, this.data)
    this.loading = false

    if(res.data.success){
      this.router.navigate(['/cards'])
    }
    
  }

  
}
