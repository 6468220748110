<div class="page-title"><span class="font-mute">Главная</span> > {{title}}</div>

<div class="container bg-block b-rounted p-4">

  <div class="block-setting">
    <div class="fs-5 mb-3">Подключение Telegram</div>

    <div class="row">
      <div class="col-xl-4">
        <div class="mb-1">Chat ID</div>
        <div class="block-input-icon">
          <input type="text" class="input-border font-yallow" value="82374724739294" >
          <img src="/assets/svg/copy.svg" width="24">
        </div>
      </div>

      <div class="col-xl-4">
        <div class="mb-1">Tg username</div>
        <div class="block-input-icon">
          <input type="text" class="input-border font-yallow" value="Designer_3000" >
          <img src="/assets/svg/copy.svg" width="24">
        </div>
      </div>
      
      <div class="col-xl-4 pt-1">
        <button class="btn bg-red-2 font-red-2 btn-lg btn-rounted w-100 mt-4">Отключить</button>
      </div>
    </div>

  </div>

</div>