<div class="page-title">{{ title.getTitle() }}</div>

<div class="container bg-block b-rounted p-4">
  <div class="row">
    <div class="col-xl-6">
      <label class="mb-1">Название банка</label>
      <input type="text" class="input" maxlength="16" placeholder="Сбербанк" [(ngModel)]="data.name" />   
    </div>
    <div class="col-xl-6">
      <label class="mb-1">Ключ</label>
      <input type="text" class="input" placeholder="sberbank" [(ngModel)]="data.key" />   
    </div>
  </div>

  <button class="btn btn-light btn-rounted w-100 mt-4 p-3" (click)="action()">

    <div class="spinner-border spinner-border-sm text-success me-3" role="status" *ngIf="loadingSent">
      <span class="visually-hidden">Loading...</span>
    </div>
    
    <span *ngIf="!loadingSent">Сохранить информацию</span> 
  </button>
</div>