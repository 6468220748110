import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  selector: 'withdraw',
  imports: [MatSlideToggleModule],
  templateUrl: './withdraw.component.html',
})
export class Withdraw{
  title = 'Мои заявки';

  modal = true
}
