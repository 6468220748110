import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  selector: 'operations',
  imports: [MatSlideToggleModule],
  templateUrl: './deposits.component.html',
})
export class Deposits {
  title = 'История пополнений';
}
