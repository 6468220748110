import * as cookie from "@/common/cookies";
import type { AxiosInstance, AxiosPromise } from "axios";
import axios from "axios";

//export type DataType = object | undefined | null;
export type ParamsType = object | undefined;

const token = cookie.get("auth") ? JSON.parse(cookie.get("auth") ?? '').token : null

const headers: any = {
  'Content-Type': 'application/json'
}

if(token)
  headers['Authorization'] = `Bearer ${token}`


export class Http {
  baseUrl: string;
  #cookie: any;
  #Axios: AxiosInstance;
  token: string | null;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.#cookie = cookie;
    this.token = token;
    this.#Axios = axios.create({
      timeout: 30000,
      headers
    });
  }
  //временное преобразование значение объекта в  string 
  convertValuesToString(obj: any) {
    if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                this.convertValuesToString(obj[key]);
            } else {
                obj[key] = String(obj[key]);
            }
        }
    }
    return obj;
}

  getFullUrl(url: string): string {
    return this.baseUrl ? new URL(url, this.baseUrl).href : url;
  }

  get(url: string, params?: ParamsType): AxiosPromise {
    return this.#Axios.get(this.getFullUrl(url), params);
  }

  post(url: string, data?: ParamsType, params?: ParamsType): AxiosPromise {
    return this.#Axios.post(this.getFullUrl(url), this.convertValuesToString(data), params);
  }

  patch(url: string, data?: ParamsType, params?: ParamsType): AxiosPromise {
    return this.#Axios.patch(this.getFullUrl(url), this.convertValuesToString(data), params);
  }

  put(url: string, data: ParamsType, params: ParamsType): AxiosPromise {
    return this.#Axios.put(this.getFullUrl(url), data, params);
  }

  delete(url: string, params: ParamsType): AxiosPromise {
    return this.#Axios.delete(this.getFullUrl(url), params);
  }

  // setToken(token: string) {
  //   this.token = token;
  //   this.#Axios = axios.create({
  //     timeout: 30000,
  //     headers: {
  //       Authorization: `Bearer ${this.token}`,
  //       //token: this.token,
  //     },
  //   });
  // }
}
