<div class="page-title mt-4 mb-3"><span class="font-mute">{{ title.getTitle() }}</span> > Заявка #{{ requestId }}</div>

<div class="spinner-border spinner-border-sm text-success" role="status" *ngIf="loadingPayment"></div>

<div class="table-responsive mt-3" *ngIf="!loadingRequest && !loadingPayment">
  <table class="table">
    <thead>
      <tr>
        <td>Создано</td>
        <td>ID платежа</td>
        <td>Трейдер</td>
        <td>Статус</td>
        <td>User</td>
        <td>Сумма</td>
        <td>Измененная сумма</td>
        <td>ФИО Карты</td>
        <td>Номер карты</td>
        <td>Валюта</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{payment.created_at}}</td>
        <td>{{payment.id}}</td>
        <td><span class="pe-2">test</span> </td>
        <td><span class="font-yallow pe-2">{{payment.status}}</span></td>
        <td>{{payment.merchants_user_id}}</td>
        <td>{{payment.amount}} Р</td>
        <td>{{payment.amount_real}} Р</td>
        <td>Петя Петров Петрович</td>
        <td>*4567</td>
        <td>RUB</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="spinner-border spinner-border-sm text-success" role="status" *ngIf="loadingRequest"></div>

<div class="row mt-4" *ngIf="!loadingRequest">
  <div class="col-xl-8">
    <div class="table-responsive">
      <div class="table-info">
        <table class="table">
          <tbody>
            <tr>
              <td>Дата и время заявки</td>
              <td>{{ request.created_at }}</td>
            </tr>
            <tr>
              <td>ID платежа</td>
              <td>{{ request.payment_id }}</td>
            </tr>
            <tr>
              <td>Сумма</td>
              <td>{{ request.new_amount }}</td>
            </tr>
            <tr>
              <td>Сообщение чекер</td>
              <td>{{ request.checker_message }}</td>
            </tr>
            <tr>
              <td>Файлы чекер</td>
              <td>
                <div class="d-flex flex-column">
                  <a href="#" *ngFor="let item of request.checker_files" class="text-light">{{item}}</a>
                </div>
              </td>
            </tr>
            <tr>
              <td>Ваше сообщение</td>
              <td>{{ request.trader_message }}</td>
            </tr>
            <tr>
              <td>Ваши файлы</td>
              <td>
                <div class="d-flex flex-column">
                  <a href="#" *ngFor="let item of request.trader_files" class="text-light">{{item}}</a>
                </div>
              </td>
            </tr>
            <tr>
              <td>Статус заявки</td>
              <td>
                <div *ngIf="request.status == 'unknown'">
                  <button class="btn btn-sm btn-success me-3" (click)="selectStatus = 'confirm'"><img src="/assets/svg/check-light.svg" width="20"></button>
                  <button class="btn btn-sm btn-danger" (click)="selectStatus = 'cancel'"><img src="/assets/svg/close.svg" width="20"></button>
                </div>

                <div *ngIf="request.status != 'unknown'" [innerHTML]="getStatusCheckerRequestHtml(request.status)"></div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="col-xl-4 mt-xl-0 mt-4">
    <div class="d-flex flex-column justify-content-center block-confirm bg-yallow-blur" *ngIf="selectStatus == 'confirm'">
      <div class="border-bottom">
        <div class="d-flex gap-3 p-4 title align-items-center">
            <img src="/assets/svg/arrow-left.svg" width="13" (click)="selectStatus = ''"> 
            <span>Подтверждение заявки</span>
        </div>
      </div>

      <div class="p-4 pt-3">
        <div>Новая сумма</div>
        <input type="text" [(ngModel)]="newAmount" class="mb-3">
        
        <button class="btn bg-yallow w-100 btn-rounted p-3" *ngIf="loadingSent">
          <div class="spinner-border spinner-border-sm text-success" role="status"></div>
        </button>
        <button class="btn bg-yallow w-100 btn-rounted p-3" *ngIf="!loadingSent" (click)="confirm()">Подвердить заявку</button>
      </div>
    </div>

    <div class="d-flex flex-column justify-content-center block-cancel bg-red-blur" *ngIf="selectStatus == 'cancel'">
      <div class="border-bottom">
        <div class="d-flex gap-3 p-4 title align-items-center">
            <img src="/assets/svg/arrow-left.svg" width="13" (click)="selectStatus = ''"> 
            <span>Отмена заявки</span>
        </div>
      </div>

      <div class="p-4 pt-3">
        <div class="mb-1">Напишите комментарий</div>
        <textarea [(ngModel)]="traderMessage"></textarea>

        <div class="mb-1 mt-2">Прикрепите файлы</div>
        <div class="list">
          <div class="d-flex justify-content-between mb-2 item" *ngFor="let item of files; let i = index">
            <span>{{ item.name }}</span>
            <img src="/assets/svg/close.svg" width="24" (click)="files.splice(i, 1)">
          </div>
        </div>
        <input type="file" id="file-upload" class="custom-file-upload me-2" (change)="onFileSelected($event)" multiple />

        <label for="file-upload" class="d-flex justify-content-between mb-2 add">
          <span>Добавить</span>
          <img src="/assets/svg/add-circle.svg"  width="24">
        </label>

        <button class="btn bg-red w-100 btn-rounted mt-4 text-light p-3" *ngIf="loadingSent">
          <div class="spinner-border spinner-border-sm text-light align-items-center" role="status"></div>
        </button>
        <button class="btn bg-red w-100 btn-rounted mt-4 text-light p-3" (click)="cancel()" *ngIf="!loadingSent">Отменить заявку</button>
      </div>
    </div>
  </div>
</div>
