import { authGuard } from "@/common/auth.guard";
import { Profile } from "@/pages/profile/profile.component";
import { Cards } from "@/pages/cards/cards.component";
import { Deposits } from "@/pages/trader/deposits/deposits.component";
import { Operations } from "@/pages/trader/operations/operations.component";
import { Payments } from "@/pages/trader/payments/payments.component";
import { Routes } from "@angular/router";
import { CustomRoute } from "./admin.routes";
import { EditCard } from "@/pages/trader/edit-card/edit-card.component";
import { AddCard } from "@/pages/trader/add-card/add-card.component";
import { TraderCheckerRequests } from "@/pages/trader/checker-requests/checker-requests.component";
import { TraderCheckerRequest } from "@/pages/trader/checker-request/checker-request.component";
import { WithdrawPull } from "@/pages/trader/withdraw-pull/withdraw-pull.component";
import { Withdraw } from "@/pages/trader/withdraw/withdraw.component";
import { Settings } from "@/pages/trader/settings/settings.component";
import { Transfer } from "@/pages/trader/transfer/transfer.component";
import { Wallet } from "@/pages/trader/wallet/wallet.component";
import { KeyApp } from "@/pages/trader/key-app/key-app.component";

export const TraderRoutes: CustomRoute[] = [
  {
    path: 'deposits',
    component: Deposits,
    canActivate: [authGuard],
    title: 'Пополнения',
    icon: 'payments.svg',
    showMenu: true
  },
  {
    path: 'operations',
    component: Operations,
    canActivate: [authGuard],
    title: 'Операции',
    icon: 'money-in.svg',
    showMenu: true
  },
  {
    path: 'cards',
    component: Cards,
    canActivate: [authGuard],
    title: 'Карты',
    icon: 'card.svg',
    showMenu: true
  },
  {
    path: 'cards/add',
    component: AddCard,
    canActivate: [authGuard],
    title: 'Создать карту'
  },
  {
    path: 'cards/edit/:id',
    component: EditCard,
    canActivate: [authGuard],
    title: 'Ред. карты'
  },
  {
    path: 'checker-requests',
    component: TraderCheckerRequests,
    canActivate: [authGuard],
    title: 'Заявки чекера',
    icon: 'money-in.svg',
    showMenu: true
  },
  {
    path: 'withdraw-pull',
    component: WithdrawPull,
    canActivate: [authGuard],
    title: 'Пул заявок на вывод',
    icon: 'money-out.svg',
    showMenu: true
  },
  {
    path: 'withdraw',
    component: Withdraw,
    canActivate: [authGuard],
    title: 'Мои заявки',
  },
  {
    path: 'checker-request/:id',
    component: TraderCheckerRequest,
    canActivate: [authGuard],
    title: 'Заявка чекера'
  },
  
  {
    path: 'settings',
    component: Settings,
    canActivate: [authGuard],
    title: 'Настройки'
  },
  {
    path: 'transfer',
    component: Transfer,
    canActivate: [authGuard],
    title: 'Перевод средств',
    icon: 'money-out.svg',
    showMenu: true
  },
  {
    path: 'key-app',
    component: KeyApp,
    canActivate: [authGuard],
    title: 'Key App',
    icon: 'key-app.svg',
    showMenu: true
  },
  {
    path: 'wallet',
    component: Wallet,
    canActivate: [authGuard],
    title: 'Кошелек'
  },
  
   {
     path: '',
     component: Payments,
     canActivate: [authGuard],
     title: 'Платежи',
     icon: 'money-in.svg',
     showMenu: true
   },

];
