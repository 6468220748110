<div class="container d-flex align-items-center mt-3 justify-content-between d-none-1000">

  <div class="d-flex justify-content-between gap-4">
    <img src="/assets/svg/logo.svg">
    <button (click)="showMenu = !showMenu" class="btn font-yallow bg-black-hold fw-700 hover-bg-yallow" [ngClass]="{'active-menu': showMenu}"> <img src="/assets/svg/icon-menu-yallow.svg" height="24"> <span class="ps-2">Меню</span></button>
  </div>

  <div class="d-flex gap-3 align-items-center" *ngIf="showMenu">
    <ng-container *ngFor="let item of routes; let i = index" >
      <a [routerLink]="item.path" [title]="item.title" *ngIf="item?.showMenu" class="d-flex gap-2 route" [ngClass]="{ 'active': i == 0}">
        <img [src]="'/assets/svg/' + item?.icon" width="24" /> {{ item.title }}
      </a>
    </ng-container> 
  </div>

   <div class="d-flex gap-5 align-items-center" *ngIf="!showMenu">

    <div class="d-flex gap-2">
      <button class="btn bg-black-hold fw-700 text-light d-flex align-items-center"> 
        <img src="/assets/svg/currency/tether.svg" height="24"> 
        <span class="ps-2">92.3 <span class="font-yallow">₽</span></span>
      </button>
      <button class="btn bg-black-hold fw-700 text-light d-flex align-items-center"> 
        <img src="/assets/svg/currency/rub.svg" height="24"> 
        <span class="ps-2">38 000 <span class="font-yallow">₽</span></span>
      </button>
      <button class="btn bg-black-hold fw-700 text-light d-flex align-items-center"> 
        <img src="/assets/svg/icon-hold-balance.svg" height="24"> 
        <span class="ps-2">92.3 <span class="font-yallow">₽</span></span>
      </button>
    </div>

    <div class="d-flex gap-2 align-items-center ">
      <a [routerLink]="'/wallet'">
        <button class="btn bg-black-hold header-icon"> 
          <img src="/assets/svg/wallet.svg"> 
        </button>

      </a>

      <a [routerLink]="'/settings'">
        <button class="btn bg-black-hold header-icon"> 
          <img src="/assets/svg/settings.svg"> 
        </button>
      </a>
      
      <a [routerLink]="'/notifs'">
        <button class="btn bg-black-hold header-icon position-relative"> 
          <img src="/assets/svg/notifs-new.svg" height="17" class="notifs-new"> 
          <img src="/assets/svg/notifs.svg"> 
        </button>
      </a>

      <button class="btn bg-black-hold header-icon"> 
        <img src="/assets/svg/logout.svg"> 
      </button>
    </div>
  </div> 

</div>



<div class="d-block-1000">
  <div class="container d-flex align-items-center mt-3 justify-content-between ">

      <div class="d-flex justify-content-between gap-4">
        <img src="/assets/svg/logo.svg">
      </div>

      <div class="d-flex gap-5 align-items-center">
        <div class="d-flex gap-2 align-items-center ">
          <a [routerLink]="'/wallet'">
            <button class="btn bg-black-hold header-icon"> 
              <img src="/assets/svg/wallet.svg"> 
            </button>

          </a>

          <a [routerLink]="'/settings'">
            <button class="btn bg-black-hold header-icon"> 
              <img src="/assets/svg/settings.svg"> 
            </button>
          </a>
          
          <a [routerLink]="'/notifs'">
            <button class="btn bg-black-hold header-icon position-relative"> 
              <img src="/assets/svg/notifs-new.svg" height="17" class="notifs-new"> 
              <img src="/assets/svg/notifs.svg"> 
            </button>
          </a>

          <button class="btn bg-black-hold header-icon"> 
            <img src="/assets/svg/logout.svg"> 
          </button>
        </div>
        <button class="btn btn-light fw-700 " (click)="showMenuMobile = !showMenuMobile"><img src="/assets/svg/icon-menu-black.svg" height="17"> <span class="ps-2">Меню</span></button>
      </div> 
  </div>

  <div class="container d-flex align-items-center mt-3 justify-content-between bg-black-hold p-2" (click)="showMobileSidebar = !showMobileSidebar">
      <div class="d-flex gap-2" *ngIf="showMobileSidebar">
        <button class="btn bg-black-hold fw-700 text-light d-flex align-items-center"> 
          <img src="/assets/svg/currency/tether.svg" height="24"> 
          <span class="ps-2">92.3 <span class="font-yallow">₽</span></span>
        </button>
        <button class="btn bg-black-hold fw-700 text-light d-flex align-items-center"> 
          <img src="/assets/svg/currency/rub.svg" height="24"> 
          <span class="ps-2">38 000 <span class="font-yallow">₽</span></span>
        </button>
        <button class="btn bg-black-hold fw-700 text-light d-flex align-items-center"> 
          <img src="/assets/svg/icon-hold-balance.svg" height="24"> 
          <span class="ps-2">92.3 <span class="font-yallow">₽</span></span>
        </button>
      </div>

      <div class="pe-4" role="button" *ngIf="showMobileSidebar"><span class="pe-2 fs-12">Скрыть</span> <img src="/assets/svg/arrow-up-mini.svg" height="7"></div>
    
      
          <div class="fs-12 ps-2" *ngIf="!showMobileSidebar">
            Показать баланс и курс
          </div>
        <div class="pe-4" role="button"  *ngIf="!showMobileSidebar"><span class="pe-2 fs-12"></span> <img src="/assets/svg/arrow-down-mini.svg" height="7"></div>
        
  </div>
</div>


<div class="bg-light mobile-menu p-4" *ngIf="showMenuMobile">
  <div class="d-flex justify-content-between">
    <div></div>
  <div><button class="btn btn-dark" (click)="showMenuMobile = !showMenuMobile"><img src="/assets/svg/close.svg" width="17"><span class="ps-3">Закрыть</span></button></div>
  </div>

  <div class="mt-2 d-flex flex-column">
    <ng-container *ngFor="let item of routes; let i = index" >
      <a [routerLink]="item.path" [title]="item.title" *ngIf="item?.showMenu" (click)="showMenuMobile = !showMenuMobile" class="d-flex gap-2 route-mobile" [ngClass]="{ 'active': i == 0}">
        <img [src]="'/assets/svg/' + item?.icon" width="24" /> {{ item.title }}
      </a>
    </ng-container> 
  </div>
</div>
