import * as cookie from "@/common/cookies";
import { resolveForwardRef } from "@angular/core";
import { Router } from "@angular/router"

export const makeParams = (query: Record<string, string | []>): string => {
  let url = "";
  for (const item in query) {
    if (!Array.isArray(query[item])) {
      url += `&${item}=${query[item]}`;
    } else {
      for (const sub of query[item]) {
        url += `&${item}[]=${sub}`;
      }
    }
  }
  return Object.keys(url).length > 0 ? `?${new URLSearchParams(url)}` : "";
};

export const isRole = (role: string) => {

  const currentRole = cookie.get("auth") ? JSON.parse(cookie.get("auth") as string).role : null

  if(!currentRole) return false

  return role === currentRole

}

export const getStatusCheckerRequestHtml = (status: string): string => {

  if(status == 'confirmed') return '<span class="text-success">Подтвержден</span>';
  if(status == 'cancel_trader') return '<span class="text-danger">Отменен трейдером</span>';
  if(status == 'cancel_checker') return '<span class="text-danger">Отменен чекером</span>';

  return 'Не определен';

}

export const getFileExtension = (fileName: string): string => {
  const parts = fileName.split('.');
  return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : '';
}

