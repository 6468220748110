import { NgModule } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { SmartTable } from '@/components/smart-table/smart-table.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
      CommonModule,
      MatSlideToggleModule, 
      SmartTable, 
      NgFor, 
      FormsModule, 
      MatFormFieldModule, 
      MatInputModule, 
      MatSelectModule, 
      FormsModule,
      CommonModule,
      RouterModule
    ],
    //declarations: [SmartTable],
    exports: [
      CommonModule,
      MatSlideToggleModule, 
      SmartTable, 
      NgFor, 
      FormsModule, 
      MatFormFieldModule, 
      MatInputModule, 
      MatSelectModule, 
      FormsModule,
      CommonModule,
      RouterModule
    ], 
  })
export class SharedModule {}
