import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  selector: 'wallet',
  imports: [MatSlideToggleModule],
  templateUrl: './wallet.component.html',
})
export class Wallet {
  title = 'Кошелек';

}
