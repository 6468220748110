import { SharedModule } from '@/app/chared.module';
import { defaultFilter } from '@/common/default';
import { api } from '@/network';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'checker-request-in',
  imports: [SharedModule],
  templateUrl: './component.html',
})
export class CheckerRequestsPayments {

  constructor(public title: Title){}

  modal: boolean = false

  data:any  = null
  loading: boolean = true
  params = defaultFilter

  async ngOnInit() {
    await this.getData()
  }

  setFiles = (data: any) => {
    const files = JSON.parse(data.val)

    if(files.length > 0){
      return 'Да'
    }else{
      return 'Нет'
    }


  }


  config: any = {
    ignore: [
      'user_id',
      'is_view_checker',
      'is_view_trader',
      'up_time',
    ],
    filters: [
        {
          field: 'id',
          type: 'input',
          label: 'ID'
        },
        {
          field: 'is_archived',
          type: 'list',
          label: 'Архив',
          list: [
            {
              value: "true",
              label: 'Да'
            },
            {
              value: "false",
              label: 'Нет'
            },
          ]
        },
        {
          field: 'is_sbp',
          type: 'boolean',
          label: 'СБП',
        },
    ],
    actions: {},
    views: {
      checker_files: this.setFiles
    },
    clickRow: () => {}
  }

  async setFilter(query: any){
    this.params = query
    await this.getData()
  }
  

  async getData(){
    this.loading = true

    const res = await api.getCheckerRequestPayment()

    this.loading = false

    console.log(res.data)

    this.data = res.data
    
  }
}
