import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  selector: 'payments',
  imports: [MatSlideToggleModule, NgIf],
  templateUrl: './component.html',
})
export class CheckerRequestsWithdraw{

  title = 'Заявки чекера на вывод';

  modal = false
}
