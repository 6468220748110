import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { namesFields } from './names.fields';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [MatGridListModule, RouterModule, CommonModule, FormsModule],
  selector: 'smart-table',
  templateUrl: './smart-table.component.html'
})

export class SmartTable {
  @Output() emitSetPage = new EventEmitter<number>();
  @Output() emitSetFilter = new EventEmitter();
  
  @Input() config!: any;
  @Input() list!: any;
  @Input() loading!: boolean;

  Object = Object
  namesFields: Record<string, string> = namesFields
  sorted: Record<string, boolean> = {}
  page: number = 1
  showFilters: boolean = false
  filters: Record<string, any> = {}
  

  getVisiblePages() {
    const visiblePages = [];

    const startPage = Math.max(1, this.page - 2);
    const endPage = Math.min(this.list.last_page, this.page + 2);

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  }

  getFiltersFormat(){
    let filters: any = []
    if(this.config.filters){

    this.config.filters.map((item: any) => {
      if(this.filters[item.field] && this.filters[item.field] != "null"){
        filters.push({
            conditionOperator: 'AND',
            column: item.field,
            comparasionOperator: '=',
            value: this.filters[item.field]
        })
      }
    })
  }

    return filters
  }

  setFilter(){
    this.page = 1
    this.setEmit();
  }

  setPage(page: number){
    if(this.page == page) return
    this.page = page
    this.setEmit();
  }

  isActions(key: string){
    if(!this.config.actions) return false 
    return key in this.config.actions
  }

  isView(key: string){
    if(!this.config.views) return false 
    return key in this.config.views
  }

  async setAction(event: any, data: any){
    event.stopPropagation();
    //console.log(data.index)
    //console.log(this.config.actions[data.field][data.index_action])
    //this.config.actions[data.field][data.index_action].loading = true
    await this.config.actions[data.field][data.index_action].action(data)
    //this.config.actions[data.field][data.index_action].loading = false
  }

  getView(data: any){
    return this.config.views[data.field](data)
  }


  setEmit(){
    const params = { params: { queryConfig: JSON.stringify({filters: this.getFiltersFormat(), pagination:{ page:this.page, size:4}})}}
    this.emitSetFilter.emit(params);
  }

  setSort(key: string){
    this.sorted[key] = !this.sorted[key]
  }


  ngOnChanges(changes: any): void {
    // удаляем ignore поля
    if (changes['list'] && changes['list'].currentValue) {
        this.list.data = changes['list'].currentValue.data?.map((obj: any) => {
          return Object.keys(obj)
              .filter(key => !this.config.ignore.includes(key))
              .reduce((acc:any, key) => {
                  acc[key] = obj[key];
                  return acc;
              }, {});
        });
      }
  }

  // async ngOnInit() {

  //   if(this.config.filters){
  //     this.config.filters.map((item: any) => {
  //       this.filters[item.field] = null
  //     })
  //   }
  // }
  
}
