<div class="page-title mt-4 mb-3"><span class="font-mute">{{ title }}</span> > Заявка #58348</div>

<div class="row mt-4">
  <div class="col-12">
    <div class="table-info">
      <table class="table table-first-column">
        <tbody>
          <tr>
            <td>Дата и время заявки</td>
            <td>2024-03-19  <span class="font-mute">16:47</span></td>
          </tr>
          <tr>
            <td>ID платежа</td>
            <td>1234567 <button class="btn bg-yallow-blur btn-rounted text-light">Открыть</button></td>
          </tr>
          <tr>
            <td>Сумма</td>
            <td>200 P</td>
          </tr>
          <tr>
            <td>Ваше сообщение</td>
            <td>не пришло сообщение</td>
          </tr>
          <tr>
            <td>Сообщение трейдера</td>
            <td>не пришло сообщение</td>
          </tr>
          <tr>
            <td>Ваши файлы</td>
            <td>
              <div class="d-flex gap-2 block-files-checker">

                <div class="d-flex justify-content-between mb-2 item">
                  <span>test.pdf</span>
                  <img src="/assets/svg/close.svg"  width="24">
                </div>

                <div class="d-flex justify-content-between mb-2 item">
                  <span>test.pdf</span>
                  <img src="/assets/svg/close.svg"  width="24">
                </div>

                <div class="d-flex justify-content-between mb-2 item-add">
                  <span>Добавить</span>
                  <img src="/assets/svg/add-circle.svg"  width="24">
                </div>

              </div>
            </td>
          </tr>
          <tr>
            <td>Файлы трейдера</td>
            <td>
              <div class="d-flex gap-2 block-files-checker">

                <div class="d-flex justify-content-between mb-2 item">
                  <span>test.pdf</span>
                  <img src="/assets/svg/close.svg"  width="24">
                </div>

              </div>
            </td>
          </tr>
          <tr>
            <td>Статус</td>
            <td>
              <button class="btn btn-cancel btn-rounted">Отклонено</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>



</div>
