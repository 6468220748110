

import { CustomRoute } from '@/app/routes/admin.routes';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { ActivatedRoute, Route, Router, RouterModule } from '@angular/router';
//import { routes } from '@/app/routes/app.routes';

//console.log(routes)

@Component({
  standalone: true,
  imports: [MatGridListModule, RouterModule, CommonModule, NgIf],
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
})
export class AppNavigation {

JSON = JSON

showMenu: boolean = false
showMenuMobile: boolean = false
showHeader: boolean = false
showMobileSidebar: boolean = false

public routes: CustomRoute[] = [];

ngOnInit() {
  //this.route.url.subscribe(console.log); // UrlSegment[]
}



constructor(private router: Router, private route: ActivatedRoute) {
  this.routes = this.router.config as CustomRoute[];

  //console.log(this.router.url);

}
 

}
