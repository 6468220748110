import { makeParams } from "@/common/helpers";

export const endpoints = {

  auth: {
    login: `/user/login`
  },

  notifs:{
    getAll: `/notification`,
  },

  checkerRequest: {
    getAllPayments: `/checker-request/payment`,
    createRequest: `/checker-request`,
    getById: (id: number) => `/checker-request/${id}`,
    traderConfirm: `/checker-request/trader-confirm`,
    traderCancel: `/checker-request/trader-cancel`,
  },

  payments: {
    getAll: `/payment`,
    getById: (id: number) => `/payment/${id}`,
  },

  cards: {
    getAll: `/card/`,
    create: `/card`,
    update: (id: number) => `/card/${id}`,
    getById: (id: number) => `/card/${id}`,
    toggleIsSbp: (id: number) => `/card/${id}/toggle/is_sbp`,
  },
  
  banks: {
    getAll: '/bank',
    createBank: '/bank',
  }



};
