<div class="page-title mt-4 mb-3">{{ title.getTitle() }}</div>


<div class="block-input-icon col-6 m-lg-0 mt-2">
  <input type="text" class="input-border w-100 d-block" placeholder="Найдите платеж по ID чтобы создать заявку" [(ngModel)]="paymentId" >
  <img src="/assets/svg/search.svg" width="24" (click)="findPayment()">
</div>

<div class="spinner-border spinner-border-sm text-success mt-4" role="status" *ngIf="loadingFind" ></div>

<div class="d-none d-md-none d-lg-block" *ngIf="!loadingFind && payment">
  <div class="table-responsive mt-3">
    <table class="table">
      <thead>
        <tr>
          <td>Создано</td>
          <td>ID платежа</td>
          <td>Трейдер</td>
          <td>Статус</td>
          <td>User</td>
          <td>Сумма</td>
          <td>Измененная сумма</td>
          <td>ФИО Карты</td>
          <td>Номер карты</td>
          <td>Валюта</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{payment.created_at}}</td>
          <td>{{payment.id}}</td>
          <td><span class="pe-2">test</span> <img src="/assets/svg/edit.svg"></td>
          <td><span class="font-yallow pe-2">{{payment.status}}</span> <img src="/assets/svg/more.svg"></td>
          <td>{{payment.merchants_user_id}}</td>
          <td>{{payment.amount}} Р</td>
          <td>{{payment.amount_real}} Р</td>
          <td>Петя Петров Петрович</td>
          <td>*4567</td>
          <td>RUB</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex gap-3 card-rounted mt-4 p-5">
    <div>
      <div class="mb-1">Введите сумму</div>
      <input type="text" class="input-small" maxlength="16" placeholder="Новая сумма" [(ngModel)]="amount"/>   
    </div>

    <div>
      <div class="mb-1">Введите сообщение</div>
      <input type="text" class="input-small" placeholder="Ваше сообщение"  [(ngModel)]="message" />   
    </div>

    <div>
      <div class="mb-1">Прикрепить файл</div>
      <div class="d-flex">
        <input type="file" id="file-upload" class="custom-file-upload me-2" (change)="onFileSelected($event)" multiple />
        <label for="file-upload" class="btn btn-opacity font-yallow me-2">Добавить <img src="/assets/svg/add-yallow.svg"> </label>
        <button class="btn btn-file" *ngFor="let item of files; let i = index" (click)="removeFile(i)">{{ item.name }}</button> 
      </div>
    </div>
  </div>

  <button class="btn bg-yallow-blur font-yallow btn-rounted w-100 mt-4 p-3" (click)="send()" *ngIf="!loadingSend">Отправить заявку</button>
  <button class="btn bg-yallow-blur font-yallow btn-rounted w-100 mt-4 p-3" *ngIf="loadingSend">
    <div class="spinner-border spinner-border-sm text-warning me-3" role="status" ></div>
  </button>
</div>

<div class="d-md-block d-lg-none ">

  <div class="table-responsive mt-3">
    <div class="table-info">
      <table class="table table-first-column">
        <tbody>
          <tr>
            <td>Дата и время заявки</td>
            <td>2024-03-19  <span class="font-mute">16:47</span></td>
          </tr>
          <tr>
            <td>ID платежа</td>
            <td>1234567 <button class="btn bg-yallow-blur btn-rounted text-light">Открыть</button></td>
          </tr>
          <tr>
            <td>Трейдер</td>
            <td><span class="pe-2">test</span> <img src="/assets/svg/edit.svg"></td>
          </tr>
          <tr>
            <td>Статус</td>
            <td><span class="font-yallow pe-2">True</span> <img src="/assets/svg/more.svg"></td>
          </tr>
          <tr>
            <td>Сумма</td>
            <td>200 P</td>
          </tr>
          <tr>
            <td>Ваше сообщение</td>
            <td>не пришло сообщение</td>
          </tr>
          <tr>
            <td>Сообщение трейдера</td>
            <td>не пришло сообщение</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>


    <div class="card-rounted  d-flex flex-column gap-3  mt-4 p-5">
      <div>
        <div class="mb-1">Введите сумму</div>
        <input type="text" class="input-small" maxlength="16" placeholder="0000 0000 0000 0000" value="8000 2993 9230 0284" />   
      </div>
  
      <div>
        <div class="mb-1">Введите сообщение</div>
        <input type="text" class="input-small" />   
      </div> 
  
      <div>
        <div class="mb-1">Прикрепите файл</div>
        <div class="d-flex flex-column gap-2">
          <button class="btn btn-file text-start">testbewherjfmneefkeksejei.pdf </button> 
          <button class="btn btn-file  text-start">testbewherjfmneefkeksejei.pdf </button> 
          <button class="btn btn-opacity font-yallow d-flex justify-content-between">Добавить <img src="/assets/svg/add-yallow.svg"></button>
      </div>
      </div>

    </div>

    <button class="btn bg-yallow-blur font-yallow btn-rounted w-100 mt-4 p-3">Отправить заявку</button>

</div>
