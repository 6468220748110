export function set(
  name: string,
  value: string,
  time = 1000 * 60 * 60 * 24 * 365,
): void {
  const date = new Date(new Date().getTime() + time).toUTCString();
  document.cookie = `${name}=${value}; path=/; expires=${date}`;
}

export function get(name: string): string | undefined {
  //eslint-disable-next-line
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function unset(name: string): void {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}
