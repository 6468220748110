import { Http, ParamsType } from "@/network/http";
import { AxiosPromise } from "axios";
import { endpoints } from "./endpoints";

export class Api {
  http: Http;
  
  constructor() {
    this.http = new Http("https://t1.new.api.bloomis.co");
  }

  // AUTH
  async login(data: ParamsType): Promise<AxiosPromise> {
    return await this.http.post(endpoints.auth.login, data);
  }

  // CHECKER_REQUESTS
  async getCheckerRequestPayment(data?: ParamsType): Promise<AxiosPromise> {
    return await this.http.get(endpoints.checkerRequest.getAllPayments, data);
  }

  async createCheckerRequest(data?: ParamsType): Promise<AxiosPromise> {
    return await this.http.post(endpoints.checkerRequest.createRequest, data);
  }

  async getCheckerRequest(id: number): Promise<AxiosPromise> {
    return await this.http.get(endpoints.checkerRequest.getById(id));
  }

  async checkerRequestTraderConfirm(data?: ParamsType): Promise<AxiosPromise> {
    return await this.http.post(endpoints.checkerRequest.traderConfirm, data);
  }

  async checkerRequestTraderCancel(data?: ParamsType): Promise<AxiosPromise> {
    return await this.http.post(endpoints.checkerRequest.traderCancel, data);
  }

  // NOTIFS
  async getNotifs(data?: ParamsType): Promise<AxiosPromise> {
    return await this.http.get(endpoints.notifs.getAll, data);
  }

  // PAYMENTS
  async getPayments(data?: ParamsType): Promise<AxiosPromise> {
    return await this.http.get(endpoints.payments.getAll, data);
  }

  async getPayment(id: number): Promise<AxiosPromise> {
    return await this.http.get(endpoints.payments.getById(id));
  }

  // CARDS
  async getCards(data?: ParamsType): Promise<AxiosPromise> {
    return await this.http.get(endpoints.cards.getAll, data);
  }

  async getCard(id: number): Promise<AxiosPromise> {
    return await this.http.get(endpoints.cards.getById(id));
  }

  async updateCard(id: number, data: ParamsType): Promise<AxiosPromise> {
    return await this.http.patch(endpoints.cards.update(id), data); 
  }

  async createCard(data?: ParamsType): Promise<AxiosPromise> {
    return await this.http.post(endpoints.cards.create, data);
  }

  async toggleIsSbp(id: number): Promise<AxiosPromise> {
    return await this.http.post(endpoints.cards.toggleIsSbp(id));
  }

  // BANKS
  async getBanks(data?: ParamsType): Promise<any> {
    return (await this.http.get(endpoints.banks.getAll, data)).data;
  }  

  async createBank(data?: ParamsType): Promise<any> {
    return (await this.http.post(endpoints.banks.createBank, data)).data;
  }  

  






}

export const api = new Api();
