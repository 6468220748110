import { authGuard } from "@/common/auth.guard";
import { CustomRoute } from "./admin.routes";

// PAGES
import { CheckerRequestsPayments } from "@/pages/checker/requests-payments/component";
import { CheckerRequest } from "@/pages/checker/request/component";
import { CheckerRequestsWithdraw } from "@/pages/checker/requests-withdraw/component";
import { CheckerCreateRequest } from "@/pages/checker/create-request/component";
import { EditBalanceTrader } from "@/pages/admin/edit-balance-trader/component";
import { DepositMerchant } from "@/pages/admin/deposit-merchant/component";

export const CheckerRoutes: CustomRoute[] = [
  
   {
     path: '',
     component: CheckerRequestsPayments,
     canActivate: [authGuard],
     title: 'Заявки на ввод',
     icon: 'money-in.svg',
     showMenu: true
   },
  
   {
     path: 'checker-request/:id',
     component: CheckerRequest,
     canActivate: [authGuard],
     title: 'Заявка чекера на ввод',
     icon: 'money-in.svg'
   },
  
   {
     path: 'withdraw',
     component: CheckerRequestsWithdraw,
     canActivate: [authGuard],
     title: 'Заявка на вывод',
     icon: 'money-in.svg',
     showMenu: true
   },
  
   {
     path: 'create-request',
     component: CheckerCreateRequest,
     canActivate: [authGuard],
     title: 'Создать заявку',
     icon: 'money-in.svg'
   },
  
   // admin routs не забыть перенсести
   {
     path: 'edit-balance-trader',
     component: EditBalanceTrader,
     canActivate: [authGuard],
     title: 'Депозит/списание трейдера',
     icon: 'money-in.svg'
   },
   {
     path: 'deposit-merhcant',
     component: DepositMerchant,
     canActivate: [authGuard],
     title: 'Пополнение мерчанта',
     icon: 'money-in.svg'
   },

];
