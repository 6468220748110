<div class="row align-items-center">
  <div class="col-lg-6"><div class="page-title m-0">{{ title.getTitle() }}</div></div>

  <div class="col-lg-6 ">
    <div class="d-flex gap-3 justify-content-lg-end justify-content-md-center ">
     <div class="d-flex flex-lg-grow-0 flex-md-grow-1">
      <select class="input-select text-light" >
        <option value="">Все способы</option>
        <option value="b1">Pending</option>
        <option value="b2">Canceled</option>
      </select>
    </div>
    
      <div class="block-input-icon flex-lg-grow-0 flex-md-grow-1">
        <input type="text" class="input-border w-100 d-block" placeholder="Поиск по ID" >
        <img src="/assets/svg/search.svg" width="24">
      </div>
    </div>
  </div>
</div>

<div class="d-flex mt-3 mb-3">
  <a [routerLink]="'/create-request'" class="btn btn-light btn-lg btn-rounted ps-4 d-flex align-items-center gap-2 text-nowrap">
      <img src="assets/svg/add-circle-dark.svg" width="22"> 
      <span>Создать заявку</span> 
  </a>
</div>

<smart-table [config]="config" [list]="data" [loading]="loading" (emitSetFilter)="setFilter($event)"></smart-table>

<div class="modal-block" *ngIf="modal">
  <div class="model-content">
    <div class="title">Вы уверены, что подтверждаете заявку?</div>
    <div class="d-flex gap-4 justify-content-center mt-4">
      <button class="btn font-yallow btn-rounted bg-yallow-blur p-2 ps-4 pe-4" (click)="modal = false">Да <img src="/assets/svg/check-light.svg" width="24"></button>
      <button class="btn font-yallow btn-rounted bg-red-blur p-2 ps-4 pe-4" (click)="modal = false">Нет <img src="/assets/svg/close-red.svg" width="24"></button>
    </div>
  </div>
</div>