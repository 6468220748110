
<div class="d-flex align-items-center">
  <div class="spinner-border spinner-border-sm text-success me-3" role="status" *ngIf="loading">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
  <!--<div class="filters mb-3" *ngIf="showFilters">
    <div class="d-flex align-items-center gap-3">
      <div *ngFor="let item of config.filters">
        <input *ngIf="item.type == 'input'" type="text" class="form-control" [placeholder]="item.label" [(ngModel)]="filters[item.field]"/>

        <select *ngIf="item.type == 'list'" class="form-select" [(ngModel)]="filters[item.field]">
          <option value="null">{{ item.label}} (Все)</option>
          <option *ngFor="let list of item.list" [value]="list.value">{{list.label}}</option>
        </select>

        <select *ngIf="item.type == 'boolean'" class="form-select" [(ngModel)]="filters[item.field]">
          <option [value]="null">{{ item.label}} (Все)</option>
          <option value="true">Да</option>
          <option value="false">Нет</option>
        </select>

        <div *ngIf="item.type == 'boolean'" class="form-check">
          <input class="form-check-input" type="checkbox" [(ngModel)]="filters[item.field]" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            {{ item.label}}
          </label>
        </div>

      </div>

    </div>
    <button class="btn btn-success mt-2" (click)="setFilter()">Применить фильтр</button>
  </div>-->


  <!-- <div class="d-flex justify-content-between align-items-center" style="display: none;">
    
   <button class="btn btn-primary brn-sm" (click)="showFilters = !showFilters" ><img src="/assets/svg/filter.svg" width="20px" ></button>

    <div class="d-flex justify-content-end align-items-center">
       <div class="spinner-border spinner-border-sm text-success me-3" role="status" *ngIf="loading">
        <span class="visually-hidden">Loading...</span>
      </div> 
      <button class="btn btn-light btn-sm" (click)="setPage(page-1)" *ngIf="page != 1">Prev</button>
      <button class="btn btn-sm ms-1 me-1" [ngClass]="{ 'btn-success': option == page, 'btn-light': option != page}" *ngFor="let option of getVisiblePages(); let i = index" (click)="setPage(option)" >{{option}}</button>
      <button class="btn btn-light btn-sm" (click)="setPage(page+1)" *ngIf="page != list.last_page && list.last_page > 0">Next</button>
    </div>
  </div> -->

  <div class="table-responsive mt-3" *ngIf="!loading && list && list.data.length > 0">
    <table class="table">
      <thead>
        <tr>
          <td *ngFor="let item of Object.keys(list.data[0])" (click)="setSort(item)" class="text-nowrap">
            {{ namesFields[item] !== undefined ? namesFields[item] : item }}
            <!-- <img src="/assets/svg/sort-from.svg" width="20px" class="sort-icon" *ngIf="sorted[item]">
            <img src="/assets/svg/sort-to.svg" width="20px" class="sort-icon" *ngIf="!sorted[item]"> -->
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tr of list.data; let index_table = index" (click)="config.clickRow(tr)">
          <td *ngFor="let td of Object.values(tr); let i = index">

            <div *ngIf="isView(Object.keys(tr)[i])" [innerHTML]="getView({row: tr, val: td, field: Object.keys(tr)[i], index: index_table})"></div>
            <div *ngIf="!isView(Object.keys(tr)[i])">{{td}}</div>
           
            <div class="btn-group" *ngIf="isActions(Object.keys(tr)[i])">
              <!-- <img src="/assets/svg/add-circle.svg" width="20px" data-bs-toggle="dropdown" aria-expanded="false" class="sort-icon" (click)="$event.stopPropagation()">
             -->

                 <button type="button" class="btn btn-sm btn-primary me-2" 
                *ngFor="let action of config.actions[Object.keys(tr)[i]]; let index_ = index" 
                (click)="setAction($event, {val: td, id: tr?.id, index_action: index_, field: Object.keys(tr)[i], index: index_table})"
                >
                
                <span class="pe-2 text-light">{{action.title}}</span>
                <div *ngIf="action.loading" class="spinner-border spinner-border-sm text-light" role="status" >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button> 
              
            </div>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
  

  <div class="d-flex justify-content-end justify-content-md-end  justify-content-sm-between gap-3 mt-3" *ngIf="!loading && list && list.data.length > 0">

    <div class="d-flex gap-2">
      <span>Позиций</span>
      <select class="select-pagination" >
        <option value="">100</option>
        <option value="200">200</option>
        <option value="300">300</option>
      </select>
    </div>
    
    <div class="d-flex justify-content-center gap-2 align-items-center pagination-small">
      <img src="assets/svg/pagination-left-1.svg" width="24" (click)="setPage(page-1)" *ngIf="page != 1">
      <!-- <img src="assets/svg/pagination-left-2.svg" width="24"> -->
      <span class="font-yallow ps-2 pe-2">{{ page }}</span>
      <img src="assets/svg/pagination-right-1.svg" width="24" (click)="setPage(page+1)" *ngIf="page != list.last_page && list.last_page > 0">
      <!-- <img src="assets/svg/pagination-right-2.svg" width="24"> -->
    </div>
  
  </div>

  <div *ngIf="!loading && list && list.data.length == 0" class="text-center mt-5 text-secondary">Нет данных</div>


  

<!-- <div class="text-center mt-4">
  <div class="spinner-border text-success" role="status" *ngIf="loading"></div>
</div> -->