import { Component } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  selector: 'operations',
  imports: [MatSlideToggleModule],
  templateUrl: './operations.component.html',
})
export class Operations {
  title = 'Операции';
}
