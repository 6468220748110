<div class="page-title"><span class="font-mute">Главная</span> > {{title}}</div>

<div class="container bg-block b-rounted p-4">

  
    <div class="d-flex align-items-center gap-4 justify-content-between p-4">
        <div class="block-input-icon flex-grow-1">
          <input type="text" class="input-border w-100 b-rounted-10" value="Нажмите на кнопку, чтобы сгенерировать ваш ключ" >
          <img src="/assets/svg/copy.svg" width="24">
        </div>
        <button class="btn btn-light btn-rounted p-3 ps-5 pe-5">Сгенерировать ключ</button>
    </div>
  
</div>