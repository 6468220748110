<div class="page-title mt-4 mb-3">{{ title.getTitle() }}</div>

<smart-table [config]="config" [list]="data" [loading]="loading" (emitSetFilter)="setFilter($event)"></smart-table>

<!-- <div class="table-responsive">
<table class="table mt-3">
  <thead>
    <tr>
      <td>#</td>
      <td>Дата и время</td>
      <td>ID платежа</td>
      <td>Сумма</td>
      <td>Сообщение</td>
      <td>Файлы</td>
      <td>Стутус</td>
      <td></td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>123</td>
      <td>2024-03-19  <span class="font-mute">16:47</span></td>
      <td>12345</td>
      <td>3 000 Р</td>
      <td>Текст сообщения </td>
      <td>Есть</td>
      <td><button class="btn bg-yallow btn-sm">01ч. 22м. 30</button>  <span class="font-yallow p-1">В процессе</span></td>
      <td><a [routerLink]="'/checker-request/1'"><img src="/assets/svg/info.svg" width="24"></a></td>
    </tr>

  </tbody>
</table>
</div> -->